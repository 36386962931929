.contact-container {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 100px;
    padding: 20px;
    text-align: center;
    padding-bottom: 120px;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form label {
    text-align: left;
    font-weight: bold;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact-form textarea {
    resize: vertical;
    height: 100px;
}

.contact-form button {
    padding: 10px 20px;
    background-color: #e29403d3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.contact-form button:hover {
    background-color: #ad7100d3;
}