.footer {
    background-color: rgb(82, 82, 82);
    /* Dark background color for the footer */
    color: white;
    /* White text color for contrast */
    padding: 20px;
    text-align: center;
    font-size: 14px;
}

.footer-description {
    margin-bottom: 20px;
}

.footer-social,
.footer-links {
    margin-bottom: 20px;
}

.footer-social ul,
.footer-links ul {
    list-style: none;
    padding: 0;
}

.footer-social ul li,
.footer-links ul li {
    display: inline;
    margin: 0 10px;
}

.footer-social ul li a,
.footer-links ul li a {
    text-decoration: none;
    color: #e29403d3;
    /* Green color for the links */
}

.footer-social ul li a:hover,
.footer-links ul li a:hover {
    text-decoration: underline;
}

.footer-copyright {
    font-size: 12px;
    color: #000000;
}