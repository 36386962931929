.search-container {
    width: 70%;
    max-width: 1200px;
    /* Adjusted max width for overall container */
    margin: 0 auto;
    margin-top: 100px;
    padding: 20px 40px;
    /* More padding for more space on the sides */
    text-align: center;
    /* Center align text in the container */
}

.search-container h1 {
    margin-bottom: 50px;
    font-size: 2.5rem;
}

.search-container .article {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.search-container .article .article-info {
    text-align: left;
}

.search-container .article-image {
    width: 340px;
    height: 300px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .search-container {
        padding: 10px 20px;
        /* Adjust padding for smaller screens */
    }

    .article {
        padding: 10px;
        margin-bottom: 15px;
        max-width: 100%;
    }

    .article h2 {
        font-size: 1.2rem;
    }
}