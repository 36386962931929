.article {
    background-color: #f9f9f9;
    padding: 15px;
    margin-bottom: 20px;
    padding-top: 0;
    transition: transform 0.3s ease, color 0.3s ease;
}

.container h1 {
    color: #666;
    font-weight: normal;
}

.article-view-relevant-articles h3 {
    font-size: 2rem;
    font-weight: normal;
}

.article-view-container .article-content h1 {
    font-size: 3rem;
    text-align: start;
}

.share-buttons {
    font-size: 1.6rem;
}

.article-view-details {
    font-size: 1.4rem;
}

.article-info p {
    font-size: 1.6rem;
}

.article h2 {
    color: #333;
    font-size: 2.5rem;
    transition: color 0.3s ease;
}

.article p {
    color: #666;
    font-size: 1.5rem;
}

.article-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
    transition: transform 0.3s ease;
}

.profile-pic {
    border-radius: 50%;
    width: 150px;
    /* Adjust size as needed */
    height: 150px;
    /* Adjust size as needed */
    object-fit: cover;
    /* Ensures the image covers the circle without distortion */
}

.read-more {
    display: inline;
    color: #007BFF;
    cursor: pointer;
}

.article-link {
    text-decoration: none;
    color: inherit;
}

/* Hover Effects */
.article:hover .article-image {
    transform: scale(1.05);
}

.article:hover h2 {
    color: rgb(231, 166, 46);
}



.small-article-container:hover .article-image {
    transform: scale(1.05);
}

.small-article-container:hover .article-title {
    color: rgb(231, 166, 46);
}

/* Responsive Design */
@media (max-width: 768px) {
    .article {
        padding: 10px;
        margin-bottom: 15px;
    }

    .article h2 {
        font-size: 1.4rem;
    }

    .article-image {
        margin-bottom: 10px;
    }

    .article-info p {
        font-size: 1.1rem;
    }

    .small-article-container {
        padding: 10px;
        margin-bottom: 15px;
    }

    .small-article-container .article-title {
        font-size: 1.5rem;
    }

    .small-article-container .article-image {
        margin-bottom: 10px;
    }

    .small-article-container .article-category {
        font-size: 1.4rem;
    }

}

@media (max-width: 520px) {

    .small-article-container .article-title {
        font-size: 0.9rem;
    }

    .small-article-container .article-category {
        font-size: 0.9rem;
    }

}

.article .article-info .article-caption {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
    bottom: 10px;
    left: 5px;
    position: relative;
}

.small-article-container .article-caption {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 15px;
    bottom: 10px;
    left: 5px;
    position: relative;
}

.article-content .article-caption {
    text-align: left;
    margin-top: 0;
    margin-bottom: 0;
}

@media (max-width: 980px) {

    .article-title,
    .article h2 {
        padding-left: 10px;
        padding-right: 10px;
    }
}