.trending-container {
    margin-bottom: 100px;
    width: 100%;
    color: white;
    padding: 0 0 20px 0;
    text-align: center;
    top: 83px;
    position: relative;
}

.carousel-article {
    height: 600px;
    background-size: cover;
    background-position: center;
    position: relative;
}

.carousel-caption {
    /* background: rgba(0, 0, 0, 0.5); */
    color: white;
    padding: 10px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
    display: flex;
    align-items: start;
    justify-content: start;
    padding-left: 100px;
    display: flex;
    flex-direction: column;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
}


.container h1 {
    position: relative;
    /* Ensure relative positioning for proper vertical line */
    display: inline-block;
    /* Make sure h1 doesn't take full width */
    margin-bottom: 20px;
    right: 5px;
    /* Adjust margin as needed */
}

.container h1::before {
    content: '';
    position: absolute;
    top: 5px;
    left: -10px;
    /* Adjust the position of the vertical line */
    width: 8px;
    /* Adjust the width of the vertical line */
    height: 70%;
    /* Take full height of container */
    background-color: #e29403d3;
    /* Color of the vertical line */
    border-radius: 50px;
}

.latest-articles,
.kosmos-articles,
.politismos-articles,
.oikonomia-articles {
    display: flex;
    gap: 20px;
}

.latest-article-large,
.kosmos-article-large,
.politismos-article-large,
.oikonomia-article-large {
    flex: 3;
    position: relative;
}

.latest-articles-small,
.kosmos-articles-small,
.politismos-articles-small,
.politismos-articles-small-columns,
.oikonomia-articles-small-columns {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.politismos-articles-small-columns,
.oikonomia-articles-small-columns {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.politismos-articles-small-columns .small-article-column,
.oikonomia-articles-small-columns .small-article-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.latest-article-large .article-image,
.kosmos-article-large .article-image,
.politismos-article-large .article-image,
.oikonomia-article-large .article-image {
    width: 100%;
    height: auto;
    /* border-radius: 8px; */
    margin-bottom: 15px;
}

.latest-articles-small .article-image,
.kosmos-articles-small .article-image,
.politismos-articles-small .article-image,
.politismos-articles-small-columns .article-image,
.oikonomia-articles-small-columns .article-image {
    width: 100%;
    height: 150px;
    object-fit: cover;
    /* border-radius: 8px; */
    margin-bottom: 10px;
}

.politismos-articles-small-columns h2,
.oikonomia-articles-small-columns h2 {
    font-size: 1.5rem;
}

.latest-articles-small .article h2,
.kosmos-articles-small .article h2,
.politismos-articles-small .article h2,
.oikonomia-articles-small .article h2 {
    font-size: 1.5rem;
}

.latest-articles-small .article div,
.kosmos-articles-small .article div,
.politismos-articles-small .article div,
.oikonomia-articles-small .article div {
    display: none;
}

.read-more {
    color: #328835;
    cursor: pointer;
}

.see-all {
    text-align: right;
    margin-top: 10px;
}

.see-all a {
    color: #e29403d3;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.5rem;
}

@media (max-width: 768px) {
    .see-all a {
        font-size: 1.2rem;
    }
}

.see-all a:hover {
    text-decoration: underline;
}

.latest-articles-small .article,
.kosmos-articles-small .article,
.politismos-articles-small .article,
.politismos-articles-small-columns .small-article-column,
.oikonomia-articles-small-columns .small-article-column {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* padding: 15px; */
}

.latest-articles-small .article-image,
.kosmos-articles-small .article-image,
.politismos-articles-small .article-image,
.politismos-articles-small-columns .article-image,
.oikonomia-articles-small-columns .article-image {
    width: 250px;
    height: 180px;
    object-fit: cover;
    margin-right: 20px;
}

.latest-articles-small .article-details,
.kosmos-articles-small .article-details,
.politismos-articles-small .article-details,
.politismos-articles-small-columns .article-details,
.oikonomia-articles-small-columns .article-details {
    flex: 1;
    width: 250px;
    /* Set the width of the details container to match the image width */
}

.latest-articles-small .article-category,
.kosmos-articles-small .article-category,
.politismos-articles-small .article-category,
.oikonomia-articles-small .article-category {
    color: #666;
    margin-bottom: 5px;
}

.latest-articles-small .article-title,
.kosmos-articles-small .article-title,
.politismos-articles-small .article-title,
.oikonomia-articles-small .article-title {
    color: #333;
    text-align: start;
}

.latest-articles .small-article-container {
    display: flex;
    flex-direction: row;
}

.small-article-container p,
h2 {
    margin-top: 0;
    text-align: left;
}

@media (max-width: 768px) {

    .latest-articles,
    .kosmos-articles,
    .politismos-articles,
    .oikonomia-articles {
        flex-direction: column;
    }

    .latest-article-large,
    .kosmos-article-large,
    .politismos-article-large,
    .oikonomia-article-large {
        flex: none;
    }

    .latest-articles-small,
    .kosmos-articles-small,
    .politismos-articles-small,
    .politismos-articles-small-columns,
    .oikonomia-articles-small-columns {
        flex: none;
    }

    .carousel-article {
        height: 300px;
    }

    .carousel-caption {
        padding: 5px;
    }

    .latest-articles-small .article,
    .kosmos-articles-small .article,
    .politismos-articles-small .article,
    .politismos-articles-small-columns .small-article-column,
    .oikonomia-articles-small-columns .small-article-column {
        flex-direction: column;
        align-items: flex-start;
    }

    .latest-articles-small .article-image,
    .kosmos-articles-small .article-image,
    .politismos-articles-small .article-image,
    .politismos-articles-small-columns .article-image,
    .oikonomia-articles-small-columns .article-image {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .latest-articles-small .article-details,
    .kosmos-articles-small .article-details,
    .politismos-articles-small .article-details,
    .politismos-articles-small-columns .article-details,
    .oikonomia-articles-small-columns .article-details {
        text-align: center;
        width: 100%;
    }
}

.koinonia-articles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.koinonia-article-large {
    /* flex: 1 1 1; */
    margin-right: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.koinonia-article-large .article {
    width: 30%;
}

.koinonia-articles-small .small-article-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.koinonia-articles-small .small-article-container {
    width: 30%;
    flex-wrap: nowrap;
}

.koinonia-article-large .article .article-image {
    width: 100%;
    height: 30%;
}

.koinonia-article-large .article-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.koinonia-articles .small-article-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.koinonia-articles-small {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    flex: 2;
    width: 100%;
    /* justify-content: center; */
}

.koinonia-articles-small .article-details {
    display: flex;
    flex-direction: column;
}

.koinonia-articles-small .small-article-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.koinonia-articles-small .article-image {
    width: 100px;
    /* Adjust as needed */
    height: 100px;
    /* Adjust as needed */
    object-fit: cover;
    margin-right: 10px;
}

.koinonia-articles-small .article-details {
    flex-direction: column;
    justify-content: start;
}


.koinonia-articles .koinonia-article-large .article {
    margin-bottom: 0;
    padding-bottom: 0;
}

.apopseis-articles .apopseis-article-large {
    display: flex;
    flex-direction: row;
}

.apopseis-articles .apopseis-article-large .article {
    display: flex;
    flex-direction: row;
}

.apopseis-articles .apopseis-article-large .article h2 {
    font-size: 1.4rem;
}

.apopseis-articles .apopseis-article-large .article p {
    font-size: 1.2rem;
}

@media (max-width: 1100px) {

    .apopseis-articles .apopseis-article-large {
        display: flex;
        flex-direction: row;
    }

    .apopseis-articles .apopseis-article-large .article {
        display: flex;
        flex-direction: row;
    }

    .apopseis-articles .apopseis-article-large .article h2 {
        font-size: 1rem;
    }

    .apopseis-articles .apopseis-article-large .article p {
        font-size: 1rem;
    }

    .profile-pic {
        width: 100px;
        height: 100px;
    }

}

@media (max-width: 830px) {

    .apopseis-articles .apopseis-article-large {
        display: flex;
        flex-direction: row;
    }

    .apopseis-articles .apopseis-article-large .article {
        display: flex;
        flex-direction: row;
    }

    .apopseis-articles .apopseis-article-large .article h2 {
        font-size: 1rem;
    }

    .apopseis-articles .apopseis-article-large .article p {
        font-size: 1rem;
    }

    .profile-pic {
        width: 70px;
        height: 70px;
    }

}

@media (max-width: 768px) {

    .apopseis-articles .apopseis-article-large {
        display: flex;
        flex-direction: column;
    }

    .apopseis-articles .apopseis-article-large .article {
        display: flex;
        flex-direction: row;
    }

    .apopseis-articles .apopseis-article-large .article h2 {
        font-size: 1rem;
    }

    .apopseis-articles .apopseis-article-large .article p {
        font-size: 1rem;
    }

    .profile-pic {
        width: 70px;
        height: 70px;
    }

}

.carousel-caption h3 {
    font-size: 4rem;
    margin: 0;
    margin-bottom: 20px;
    padding-right: 50px;
    color: white;
    background-color: rgba(41, 39, 39, 0.623);
    text-align: start;
    width: 70%;

}

.carousel-caption p {
    font-size: 2rem;
    margin: 0;
    margin-bottom: 10px;
}

@media (max-width: 1700px) {

    .carousel-caption h3 {
        font-size: 3.5rem;
    }

}

@media (max-width: 1100px) {

    .carousel-caption h3 {
        font-size: 3rem;
    }

}

@media (max-width: 768px) {
    .koinonia-articles {
        flex-direction: column;
    }

    .koinonia-article-large,
    .koinonia-articles-small {
        display: flex;
        flex-direction: column;
    }

    .koinonia-articles-small .article-image {
        width: 100%;
        height: auto;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .koinonia-articles-small .article-details {
        text-align: center;
    }

    .politismos-articles-small-columns,
    .oikonomia-articles-small-columns {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .carousel-caption h3 {
        font-size: 1.8rem;
    }

    .koinonia-articles a {
        width: 100%;
    }

    .latest-articles a {
        width: 50%;
    }

    .latest-articles .article-details .article-link {
        width: 100%;
    }

    .koinonia-articles .article-details .article-link {
        width: 100%;
    }


    .koinonia-articles-small .small-article-container,
    .latest-articles .small-article-container {
        width: 100%;
    }

    .koinonia-articles-small .article-details,
    .latest-articles-small .article-details {
        width: 100%;
    }

    .koinonia-article-large .article {
        width: 100%;
    }

    .latest-articles-small .article::after,
    .kosmos-articles-small .article::after,
    .politismos-articles-small .article::after,
    .politismos-articles-small-columns .small-article-column::after,
    .oikonomia-articles-small-columns .small-article-column::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: black;
    }

    .latest-articles .latest-article-large .article::after,
    .kosmos-articles .kosmos-articles-small .small-article-container::after,
    .politismos-articles .politismos-article-large .article::after,
    .koinonia-articles .koinonia-article-large .article::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: black;
    }

    .latest-articles .latest-articles-small .small-article-container-outer::after,
    .koinonia-articles .koinonia-articles-small .small-article-container-outer::after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        background-color: black;
    }



}

@media (max-width: 600px) {

    .carousel-caption h3 {
        font-size: 1.3rem;
    }

}

@media (max-width: 1535px) {
    .carousel-caption h3 {
        font-size: 3.3rem;
    }

    .carousel-caption p {
        font-size: 1.9rem;
        margin: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 1460px) {
    .carousel-caption h3 {
        font-size: 3.1rem;
    }
}

@media (max-width: 1385px) {
    .carousel-caption h3 {
        font-size: 2.9rem;
    }
}

@media (max-width: 1310px) {
    .carousel-caption h3 {
        font-size: 2.7rem;
    }

    .carousel-caption p {
        font-size: 1.3rem;
        margin: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 1220px) {
    .carousel-caption h3 {
        font-size: 2.5rem;
    }
}

@media (max-width: 1155px) {
    .carousel-caption h3 {
        font-size: 2.3rem;
    }
}

@media (max-width: 1080px) {
    .carousel-caption h3 {
        font-size: 2rem;
    }
}

@media (max-width: 985px) {
    .carousel-caption h3 {
        font-size: 1.7rem;
    }
}

@media (max-width: 845px) {
    .carousel-caption h3 {
        font-size: 1.5rem;
    }

    .carousel-caption p {
        font-size: 1.1rem;
        margin: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 675px) {
    .carousel-caption h3 {
        font-size: 1.3rem;
    }

    .carousel-caption p {
        font-size: 0.9rem;
        margin: 0;
        margin-bottom: 10px;
    }
}

@media (max-width: 675px) {
    .carousel-caption h3 {
        font-size: 1.1rem;
    }
}

@media (max-width: 520px) {
    .carousel-caption h3 {
        font-size: 0.9rem;
    }
}

@media (max-width: 450px) {


    .carousel-caption h3 {
        width: 80%;
    }

}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
}


@media (max-width: 980px) {
    .container h1 {
        left: 10px;
    }
}

.carousel .control-next,
.carousel .control-prev {
    background-color: #ff5733;
    /* Change this to your desired color */
    border: none;
    /* Optional: remove border */
    color: white;
    /* Optional: change text color */
}


.carousel.carousel-slider .control-arrow {
    top: 0;
    background-color: #0000007c !important;
    font-size: 26px;
    bottom: 0;
    margin-top: 0;
    padding: 5px;
}