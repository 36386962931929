/* global.css */

/* Import Noto Serif font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Extra+Condensed:wght@200;400&display=swap');

body {
    margin-top: 37px;
    /* font-family: 'Noto Serif', serif; */
    line-height: 1.5;
    font-family: 'Fira Sans Extra Condensed', sans-serif;
}

@media (max-width: 980px) {

    body {
        margin-top: 0px;
        /* font-family: 'Noto Serif', serif; */
        font-family: 'Fira Sans Extra Condensed', sans-serif;
    }

}