.category-container {
    width: 70%;
    max-width: 1200px;
    /* Adjusted max width for overall container */
    margin: 0 auto;
    padding: 20px 40px;
    /* More padding for more space on the sides */
    text-align: center;
    /* Center align text in the container */
    margin-top: 100px;
}

.category-container h1 {
    margin-bottom: 50px;
    font-size: 2.5rem;
    position: relative;
    text-decoration: underline;
    text-decoration-color: #e29403d3;
    text-decoration-thickness: 10px;
    text-underline-offset: 0.3em;
    text-decoration-skip-ink: none;
}



.category-container .article {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.category-container .article .article-info {
    text-align: left;
}

.category-container .article-image {
    width: 340px;
    height: 300px;
}

.category-container .category-apopsi .article-image {
    width: 250px;
    height: 250px;
}

/* Responsive Design */
@media (max-width: 1450px) {
    .category-container {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        width: 100%;
    }
}

/* Responsive Design */
@media (max-width: 1320px) {
    .category-container {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}

/* Responsive Design */
@media (max-width: 768px) {

    .article {
        padding: 10px;
        margin-bottom: 15px;
        max-width: 100%;
    }

    .article h2 {
        font-size: 1.2rem;
    }
}

/* Responsive Design */
@media (max-width: 900px) {
    .category-container .article .article-link {
        width: 50%;
    }

    .category-container .article .article-info {
        width: 50%;
    }

    .category-container .article .article-image {
        width: 100%;
    }

    .category-container .category-apopsi .article-image {
        width: 200px;
        height: 200px;
    }
}

/* Responsive Design */
@media (max-width: 550px) {
    .category-container .article .article-link {
        width: 60%;
    }

    .category-container .article .article-info {
        width: 40%;
    }

    .category-container .article .article-image {
        width: 100%;
    }

    .category-container .category-apopsi .article-image {
        width: 180px;
        height: 180px;
    }
}

/* Responsive Design */
@media (max-width: 450px) {
    .category-container .article .article-link {
        width: 40%;
    }

    .category-container .article .article-info {
        width: 60%;
    }

    .category-container .article .article-image {
        width: 100%;
        height: auto;
    }

    .category-container .category-apopsi .article-image {
        width: 150px;
        height: 150px;
    }
}