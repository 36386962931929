.terms-container {
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.terms-container h1 {
    font-size: 3.5rem;
}

.terms-container p {
    font-size: 1.6rem;
    padding-left: 100px;
    padding-right: 100px;
}

.bold {
    font-weight: bold;
}