/* General styles */
body {
    font-size: 16px;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #4CAF50;
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
    z-index: 999;
    transition: background-color 0.5s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 6.25rem;
    /* 100px */
}

.logo-container {
    margin-right: 20px;
}

.logo {
    width: 15rem;
    /* 300px */
    height: 5.5rem;
    /* 120px */
    padding-left: 3.125rem;
    /* 50px */
}

.podcast,
.tv {
    width: 2.1875rem;
    /* 35px */
    height: 2.1875rem;
    padding-bottom: 0.25rem;
    /* 35px */
    /* 15px */
}

.tv {
    padding-left: 0.25rem;
    /* 20px */
}

.hamburger-menu {
    font-size: 1.5rem;
    /* 24px */
    cursor: pointer;
    color: white;
    margin-right: 1.25rem;
    /* 20px */
    position: fixed;
    top: 0.625rem;
    /* 10px */
    left: 0.625rem;
    /* 10px */
    z-index: 1001;
}

.sidebar {
    height: 100%;
    width: 15.625rem;
    /* 250px */
    position: fixed;
    top: 0;
    left: -15.625rem;
    /* 250px */
    background-color: #333;
    overflow-x: hidden;
    transition: 0.3s;
    padding-top: 1rem;
    /* 60px */
    z-index: 1000;
}

.sidebar.active {
    left: 0;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    padding: 0.5rem 1rem;
    /* 8px 16px */
    text-decoration: none;
    font-size: 1.5625rem;
    /* 25px */
    color: white;
    display: block;
    transition: 0.3s;
}

.sidebar ul li:hover {
    background-color: #575757;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.white {
    color: white;
}

.colored {
    color: black;
}

.radio {
    width: 1.875rem;
    /* 30px */
    height: 1.875rem;
}

.no-text-decoration {
    text-decoration: none;
}

.side-bar-item:hover {
    color: #e29403d3;
    cursor: pointer;
}

.search {
    width: 1.875rem;
    /* 30px */
    height: 1.875rem;
    /* 30px */
    margin-right: 3.125rem;
    /* 50px */
    cursor: pointer;
    padding-left: 0.625rem;
    /* 10px */
    position: relative;
}

.search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.search-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-input {
    width: 18.75rem;
    /* 300px */
    padding: 0.625rem;
    /* 10px */
    font-size: 1.125rem;
    /* 18px */
    border: 2px solid #e29403d3;
    border-radius: 0.3125rem;
    /* 5px */
    margin-bottom: 0.625rem;
    /* 10px */
}

.search-submit {
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
    font-size: 1.125rem;
    /* 18px */
    background-color: #bb7900d3;
    color: white;
    border: none;
    border-radius: 0.3125rem;
    /* 5px */
    cursor: pointer;
}

.search-submit:hover {
    background-color: #45a049;
}

.sidebar ul li a {
    text-decoration: none;
    color: white;
}

.search-list-item {
    display: flex;
    flex-direction: row;
}

.sidebar li a:hover {
    color: #e29403d3;
}

.navbar {
    padding: 0.625rem 1.25rem;
    /* 10px 20px */
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.hamburger-menu {
    margin-right: 1.25rem;
    /* 20px */
}

.logo-container {
    padding-left: 0;
    /* 20px */
}

.podcast-container {
    padding-left: 0;
    margin-left: 1.25rem;
    /* 20px */
}

.nav-bar-links {
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    /* 40px */
    font-size: 1.75rem;
    /* 2.8rem is too large, adjusted */
    align-items: center;
    list-style-type: none;
    padding-left: 0;
}

.nav-bar-links ul {
    text-decoration: none;
}

.nav-bar-links li a {
    text-decoration: none;
    font-size: 2rem;
    /* 2rem */
}

.nav-bar-links li a:hover {
    color: #e29403d3;
}

.nav-bar-links.right {
    margin-left: auto;
    /* 100px */
}

.vertical {
    display: flex;
    flex-direction: column;
}

.nav-bar-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: -1.5rem;
    /* 20px */
    top: 45%;
    transform: translateY(-50%);
}

.nav-bar-vertical .nav-bar-links.right {
    flex-direction: row;
}

.nav-bar-vertical .search-text {
    font-size: 2rem;
    /* 1.8rem is too large, adjusted */
    bottom: 1rem;
    /* 45px */
    position: relative;
    right: 0.625rem;
    /* 10px */
    align-items: center;
    display: flex;
    justify-content: center;
}

.nav-bar-vertical .search-text:hover {
    color: #e29403d3;
    cursor: pointer;
}

.nav-bar-links.right {
    margin-left: auto;
    margin-right: 4rem;
    /* 100px */
}

@media (max-width: 1600px) {
    .nav-bar-links.right {
        margin-left: auto;
        margin-right: 2rem;
        /* 100px */
    }
}


.ked_press {
    height: 5rem;
    bottom: 15px;
    right: 5px;
    position: relative;
    /* 108px */
}

.eshea-text {
    position: relative;
    right: 3.125rem;
    /* 50px */
}

.hovered-kedpress {
    color: #e29403d3;
}

@media (max-width: 1550px) {

    .nav-bar-links li a {
        text-decoration: none;
        font-size: 1.8rem;
    }

    .nav-bar-vertical .search-text {
        font-size: 1.8rem;
    }

    .nav-bar-links {
        gap: 2rem;
    }

}

@media (max-width: 1440px) {

    .nav-bar-links li a {
        text-decoration: none;
        font-size: 1.5rem;
    }

    .nav-bar-vertical .search-text {
        font-size: 1.5rem;
    }

    .nav-bar-links {
        gap: 1.5rem;
    }

    .eshea-text {
        right: 2.7rem;
    }


}

@media (max-width: 1260px) {

    .nav-bar-links li a {
        text-decoration: none;
        font-size: 1.3rem;
    }

    .nav-bar-vertical .search-text {
        font-size: 1.3rem;
    }

    .nav-bar-links {
        gap: 1rem;
    }

    .eshea-text {
        right: 2rem;
    }

    .podcast,
    .tv,
    .radio {
        width: 1.8rem;
        /* 35px */
        height: 1.8rem;
        /* 35px */
        /* 15px */
    }

}

@media (max-width: 1140px) {

    .nav-bar-links li a {
        text-decoration: none;
        font-size: 1rem;
    }

    .nav-bar-vertical .search-text {
        font-size: 1rem;
    }

    .nav-bar-links {
        gap: 0.8rem;
    }

    .eshea-text {
        right: 1.8rem;
    }

}

@media (max-width: 1100px) {


    .nav-bar-links {
        gap: 0.6rem;
    }

}

@media (max-width: 980px) {

    .navbar {
        height: 4rem;
        /* 100px */
    }

    .logo {
        width: 13rem;
        height: 4rem;
        top: 5px;
        position: relative;
        padding-left: 4rem
    }
}

/* Add this to your CSS */
.nav-bar-links li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}