/* SubscribePopup.css */

.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #cacaca; */
    background-color: #e29403;
    padding: 60px 100px 60px 100px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    width: 450px;
    /* Example width, adjust as needed */
}

.popup-inner {
    position: relative;
    text-align: center;
}

.popup h2 {
    margin-bottom: 20px;
    /* color: white; */
}

.popup form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.popup button {
    padding: 10px 20px;
    background-color: #6e4700;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.popup button.close-btn {
    position: absolute;
    top: -50px;
    right: -70px;
    width: 30px;
    height: 30px;
    font-size: 25px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #000;
    margin-top: -10px;
    /* Adjust as needed to move it more up */
    margin-right: -10px;
    /* Adjust as needed to move it more right */
}

.popup button.close-btn:hover {
    color: #dc3545;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.newsletter-submit-button {
    margin-top: 30px;
    width: 200px;
    height: 50px;
}

@media (max-width: 1000px) {


    .popup {
        width: 200px;
    }

}

@media (max-width: 425px) {


    .popup {
        width: 150px;
    }

}

@media (max-width: 370px) {


    .popup {
        width: 100px;
    }

    .popup h2 {
        font-size: 1rem;
    }

}