.editor-container {
    background: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 800px;
}

.editor-container form {
    gap: 20px;
}

.editor-container h1 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.editor-container form {
    display: flex;
    flex-direction: column;
}

.editor-container input[type="text"],
.editor-container textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.editor-container textarea {
    height: 150px;
}

.editor-container button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.editor-container button:hover {
    background-color: #45a049;
}

.new-article-button {
    display: block;
    width: 200px;
    margin: 20px auto;
    padding: 10px;
    background-color: #008CBA;
    color: white;
    text-align: center;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.new-article-button:hover {
    background-color: #007B9E;
}

.form-last-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ql-x-post::before {
    content: 'X';
    font-family: Arial, sans-serif;
    font-weight: bold;
}

.editor-container .article-editor-loader,
.admin-container .article-editor-loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}

.editor-container .article-editor-loader {
    z-index: 9999;
}

.admin-container .article-editor-loader {
    z-index: 1000;
}

.image-fieldset {
    display: flex;
    flex-direction: column;
    gap: 20px;
}