.admin-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    position: relative;
    margin-top: 150px;
}

.admin-content {
    width: 100%;
    max-width: 800px;
    text-align: center;
}

.new-article-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
}

.new-article-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.new-article-button:hover {
    background-color: #45a049;
}

.admin-header {
    text-align: center;
    margin: 20px 0;
}

.admin-container .article-container {
    position: relative;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.562);
}

.edit-button {
    position: absolute;
    top: 20px;
    right: 130px;
    padding: 5px 10px;
    background-color: #007BFF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-button:hover {
    background-color: #0056b3;
}

.delete-button {
    position: absolute;
    top: 20px;
    right: 45px;
    padding: 5px 10px;
    background-color: #be1010;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: #690000;
}

/* Admin.css */

.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.confirmation-dialog p {
    margin-bottom: 20px;
    font-size: 18px;
}

.confirmation-dialog button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirmation-dialog button:hover {
    opacity: 0.9;
}

.confirmation-dialog .confirm-button {
    background-color: #d9534f;
    color: white;
}

.confirmation-dialog .cancel-button {
    background-color: #5bc0de;
    color: white;
}

/* Optional: Add a backdrop to highlight the modal */
.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}


/* UID Display */
.uid-display {
    position: absolute;
    top: 10px;
    right: 50px;
    background-color: #333;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 1.2rem;
    z-index: 10;
}

.newsletter {
    font-size: 20px;
}

.newsletter-popup-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}

.newsletter-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    /* fixed is inherited, but relative ensures shadow positioning works correctly */
    width: 50%;
    height: 50%;
    background-color: #ffffff;
    border-radius: 20px;
    border: 2px solid #000000;
    /* Added a visible border */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    /* Added a shadow for better visibility */
}

.newsletter-popup .group-options {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
}

.newsletter-popup .popup-actions {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.newsletter-popup .popup-actions .send-button {
    background-color: #1bb13b;
    border-radius: 10px;
    width: 200px;
    height: 50px;
    font-size: 15px;
    border: 0 solid #000000;
}

.newsletter-popup .popup-actions .close-button {
    background-color: #ff0000;
    border-radius: 10px;
    width: 200px;
    height: 50px;
    font-size: 15px;
    border: 0 solid #000000;
}

.send-article-section {
    display: flex;
    padding-left: 100px;
    padding-right: 100px;
    flex-direction: column;
    gap: 20px;
}

.send-article-section .sent-to {
    display: flex;
    flex-direction: column;
}

.send-article-section .sent-to .newsletter {
    margin: 0;
}